import { useCallback, useEffect, useState } from 'react';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { CarouselArrow, ProductCategory } from '../../shared/components/';

import style from './Menu.module.css';

import { getCatalog } from '../../shared/services/catalogs';
import { getCategories } from '../../shared/services/categories';

export const Menu = () => {

  const [catalog, setCatalog] = useState<Catalog>();
  const [categories, setCategories] = useState<Category[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const catalogData = await getCatalog();
        setCatalog(catalogData);
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  useEffect(() => {
    (async () => {
      if (!catalog) return;
      console.log(catalog.id)
      
      setCategories(await getCategories(catalog.id));

    })()
  }, [catalog])

  const drawProductsSlides = useCallback(() => {
    let productSlidesList: React.ReactElement[] = [];
    console.log(categories)
    categories.map((category, key) => productSlidesList.push(
      <ProductCategory name={category.name} categoryId={category.id} key={key} />
    ));
    return productSlidesList;
  }, [categories])

  const scrollToUp = () => {
    document.documentElement.scrollTop > 160 &&
      document.documentElement.scrollTo(0, 160)
  }

  const createCarouselArrow = (direction?: 'left' | 'right') => {
    return (clickHandler: () => void, hasNext: boolean) => {
      return (hasNext && <CarouselArrow onClick={clickHandler} direction={direction} />)
    }
  }

  return (
    <div className={style.Menu}>
      <Carousel
        preventMovementUntilSwipeScrollTolerance
        swipeScrollTolerance={70}
        emulateTouch
        autoPlay={false}
        children={drawProductsSlides()}
        showIndicators={false}
        showStatus={false}
        showThumbs={false}
        renderArrowNext={createCarouselArrow('right')}
        renderArrowPrev={createCarouselArrow('left')}
        className={style.Carousel}
        onChange={() => scrollToUp()}
      />
    </div>
  );
}
