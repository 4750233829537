
import { useCartContext } from '../../context';
// import { Badge } from '../badge/Badge';
import { Price } from '../price/Price';
import style from './ProductCard.module.css';

import { useNotificationContext } from '../../context';
import { useEffect, useState } from 'react';
import { getPictures } from '../../services/pictures';
export interface IProductCard {
  sx?: React.CSSProperties,
  onClick?: (event: React.MouseEvent<HTMLElement>) => void,
  product: Product
}

export const ProductCard: React.FC<IProductCard> = ({ sx, onClick, product }) => {

  const { addProduct, HTMLRef } = useCartContext();
  const { addNotification } = useNotificationContext();
  const [firstImage, setFirstImage] = useState('');

  useEffect(() => {
    (
      async () => {
        const pictures: Picture[] = await getPictures(product.id)
        setFirstImage(`${process.env.REACT_APP_API_PICTURES_URL}${pictures[0].path}`)
      }
    )()
  }, [product])

  const addToCart = (ev: React.MouseEvent, product: Product) => {
    addProduct(product);
    addNotification({
      type: 'Success',
      title: 'Produto adicionado ao carrinho',
      delay: 2000
    });

    if (HTMLRef) {
      HTMLRef.current?.classList.add(style.ShakeCart);
      setTimeout(() => HTMLRef.current?.classList.remove(style.ShakeCart), 500);
    }
    ev.stopPropagation();
  }

  return (
    <div onClick={onClick} className={style.Container} style={{ ...sx }}>
      <div className={style.ImageContainer}>
        {/* {product?.badge && <Badge text={badge} />} */}
        <img src={firstImage} className={style.ProductImage} alt={product?.name} />
        <div className={style.ProductTitle}>
          <span>{product?.name}</span>
        </div>
      </div>
      <div className={style.DescriptionContainer}>
        {product?.description}
      </div>
      <div className={style.PriceContainer}>
        <div onClick={(ev) => addToCart(ev, product)} className={style.Cart}></div>
        <Price
          amount={product?.price || 0}
          currency={'R$'}
          className={style.Price}
        />
      </div>
    </div >
  )
}

