import { useCallback, useEffect, useState } from 'react';

import { useModalContext } from '../../context/';
import { ProductCard } from '../';

import style from './ProductCategory.module.css';
import { getProductsByCategory } from '../../services/product';


export interface IProductCategory {
  name: string,
  categoryId: string,
}

export const ProductCategory: React.FC<IProductCategory> = ({ name, categoryId }) => {
  const [products, setProducts] = useState([]);
  const { showModal, setContent } = useModalContext();

  useEffect(() => {
    (async () => {
      setProducts(await getProductsByCategory(categoryId));
    })()
  }, [categoryId])

  const openModal = useCallback((product: Product) => {
    setContent(product);
    showModal();
  }, [setContent, showModal]);

  const drawProducts = useCallback(() => {
    let elements: React.ReactElement[] = [];
    console.log(products)
    products.map((product, key) => elements.push(
      <ProductCard key={key} product={product} onClick={() => openModal(product)} />
    ))
    return elements;
  }, [openModal, products])

  return (
    <div className={style.ProductCategory}>
      <div className={style.CategoryTitle}>
        {name}
      </div>
      <div className={style.Products}>
        {drawProducts()}
      </div>
    </div>
  );
}